import { CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import eventEmitter from 'src/Event';

const ToastsErrorMsgComponent = () => {
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const handleToastError = (listErrors: string[]) => {
            setErrors(listErrors);
        };

        const handleToastClear = () => {
            setErrors([]);
        }

        eventEmitter.on('toastError', handleToastError);
        eventEmitter.on('toastClear', handleToastClear);
        return () => {
            eventEmitter.off('toastError', handleToastError);
            eventEmitter.off('toastClear', handleToastClear);
        };
    }, []);

    return (
        errors.length > 0 && (
            <CToaster position="top-right">
                {errors.map((x, i) => (
                    <CToast
                        className="toast-danger"
                        color="toast-danger"
                        key={"toast" + i}
                        show={true}
                        autohide={3000}
                        fade={true}
                    >
                        <CToastHeader closeButton={true}>
                            Error
                        </CToastHeader>
                        <CToastBody>{x}</CToastBody>
                    </CToast>
                ))}
            </CToaster>
        )
    );

    // return (
    //     errors.length > 0 &&
    //     errors.map((message) => (
    //         <ModelToast
    //             data={{
    //                 text: message,
    //                 title: "Error",
    //             }}
    //             color="toast-danger"
    //             position="top-right"
    //             autohide={3000}
    //         />
    //     ))
    // );

};

export default ToastsErrorMsgComponent;