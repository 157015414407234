// import useFetch from "src/hooks/useFetch";
import { getCookie, clearAllCookies } from "./CookieFunctionsHelper";
import { GET_ALL } from "src/api";

export default function TokenFunctionsHelper() {
  async function checkIfHaveTokenAndIsCurrentVersion(optionsRequest: any) {
    var newOptionsRequest = null;
    newOptionsRequest = await checkIfHaveToken(optionsRequest);
    checkIfIsCurrentVersion();

    return newOptionsRequest ? newOptionsRequest : optionsRequest;
  }

  async function checkIfHaveToken(optionsRequest: any) {
    if (!checkIfHaveAuthorization(optionsRequest)) return;

    const token = getTokenFromLocalStorage();
    if (!token) {
      clearStorageAndExitSystem();
      return;
    }

    if (!checkTokenValidity(token)) {
      if (!getCookie("rememberMe")) {
        clearStorageAndExitSystem();
        return;
      }
      optionsRequest = await refreshToken();
    }

    return optionsRequest;
  }

  function checkTokenValidity(token: string): boolean {
    const tokenDecoded = JSON.parse(decodeToken(token));
    return checkExpirationTimeToken(tokenDecoded.exp);
  }

  function getTokenFromLocalStorage() {
    return localStorage.getItem("token");
  }

  function setTokenInLocalStorage(token: string) {
    localStorage.setItem("token", token);
  }

  function checkIfHaveAuthorization(optionsRequest: any) {
    return optionsRequest?.headers?.Authorization;
  }

  function checkExpirationTimeToken(tokenTime: number) {
    const currentTime = new Date().getTime() / 1000; // convert milliseconds to seconds
    const notExpire = tokenTime > currentTime;
    return notExpire;
  }

  const checkIfIsCurrentVersion = async () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("version") !== process.env.REACT_APP_VERSION
    ) {
      clearStorageAndExitSystem();
      return;
    }
  };

  function clearStorageAndExitSystem() {
    clearStorage();
    window.location.href = "/";
    window.location.reload();
  }

  function clearStorage() {
    Object.keys(localStorage).forEach((x) => localStorage.removeItem(x));
    Object.keys(sessionStorage).forEach((x) => sessionStorage.removeItem(x));
    clearAllCookies();
  }

  function decodeToken(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return jsonPayload;
  }

  async function refreshToken() {
    var token = getTokenFromLocalStorage();
    const entityId = JSON.parse(decodeToken(token)).entityId;
    const { url, options } = GET_ALL("Account/refresh-token", { entityId });
    await fetch(url, options)
      .then((response) => response.json())
      .then((json) => {
        token = json.token;
        setTokenInLocalStorage(json.token);
      })
      .catch((error) => {
        clearStorageAndExitSystem();
        console.error("Error:", error);
      });

    return getTokenFromLocalStorage();
  }

  return {
    checkIfHaveTokenAndIsCurrentVersion,
    clearStorage,
    clearStorageAndExitSystem,
    decodeToken,
    getTokenFromLocalStorage,
    setTokenInLocalStorage,
  };
}
