import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import React from "react";
import { RECOVER_PASSWORD_GET } from "src/api";
import useFetch from "src/hooks/useFetch";

const PasswordExpired = ({ display }) => {
  const [modal, setModal] = React.useState(false);
  const [userName, setUserName] = React.useState(null);
  const { request } = useFetch();
  const [data, setData] = React.useState({
    title: "Password expired",
    text: "",
    justConfirm: false,
  });

  async function recoverPassword() {
    const data = {
      username: userName,
    };
    const { url, options } = RECOVER_PASSWORD_GET(data);
    const { response } = await request(url, options);

    if (response.ok) {
      setData({
        title: "Confirmation",
        text: "The confirmation e-mail was sent to the account e-mail address",
        justConfirm: true,
      });
      // setModal((modal) => !modal);
    }
  }

  React.useEffect(() => {
    display(toggle);
  }, []);

  const toggle = (text, username) => {
    setData((old) => ({ ...old, text }));
    setUserName(username);
    setModal((modal) => !modal);
  };

  return (
    <>
      <CModal show={modal} onClose={toggle} color="dark">
        <CModalHeader closeButton>{data.title}</CModalHeader>
        <CModalBody>{data.text}</CModalBody>
        {!data.justConfirm && (
          <CModalFooter>
            <CButton onClick={recoverPassword} color="success">
              Yes
            </CButton>
            <CButton
              color="primary"
              onClick={() => setModal((modal) => !modal)}
            >
              No
            </CButton>
          </CModalFooter>
        )}
        {data.justConfirm && (
          <CModalFooter>
            <CButton
              color="success"
              onClick={() => setModal((modal) => !modal)}
            >
              OK
            </CButton>
          </CModalFooter>
        )}
      </CModal>
    </>
  );
};

export default PasswordExpired;
