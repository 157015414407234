import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./scss/style.scss";
import SmsCode from "./views/auth/SmsCode";
import LoginOnboarding from "./views/auth/LoginOnboarding";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const RecoverPassword = React.lazy(() =>
  import("./views/auth/RecoverPassword")
);
const ResetPassword = React.lazy(() => import("./views/auth/ResetPassword"));
const Register = React.lazy(() => import("./views/auth/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const OnboardingTestView = React.lazy(() =>
  import("./views/Onboarding/Test/UserTab")
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/auth/onboarding"
              name="Login Page"
              render={(props) => <LoginOnboarding {...props} />}
            />
            <Route
              exact
              path="/auth/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/auth/sms-login"
              name="Login Page"
              render={(props) => <SmsCode {...props} />}
            />
            <Route
              exact
              path="/auth/recover-password"
              name="Recover Password"
              render={(props) => <RecoverPassword {...props} />}
            />
            <Route
              exact
              path="/auth/reset-password"
              name="Recover Password"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/auth/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/test/onboarding"
              name="Onboarding Test"
              render={(props) => <OnboardingTestView {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
