import { createStore } from "redux";

import { decodeToken } from "src/helpers/functions";

const initialState = {
  sidebarShow: "responsive",
  minimizeShow: true,
  token: "",
  registered: false,
  accessCount: localStorage.getItem("accessCount")
    ? localStorage.getItem("accessCount")
    : "",
  EntityName: "",
  isOnboarding: localStorage.getItem("accessCount")
    ? localStorage.getItem("accessCount") > "0"
      ? true
      : false
    : false,
  userId: "",
  organizationId: "",
  jsonPayload: {},
  routeWiki: "",
  logoEntity: null,
  hasAccountTruckSpy: false,
  isDriverApp: localStorage.getItem("token")
    ? JSON.parse(decodeToken(localStorage.getItem("token"))).profileName ===
      "driver"
    : false,
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "logout":
      state = undefined;
      return;
    default:
      return state;
  }
};

const store = createStore(
  changeState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
