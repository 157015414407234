export function setCookie(cName: string, cValue: string, expireDays: number) {
  let now = new Date();
  now.setTime(now.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + now.toUTCString();
  document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
}

export function getCookie(cName: string) {
  let name = cName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function deleteCookie(cName: string) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function clearAllCookies() {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}
