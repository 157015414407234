export function verifyDateFull(date) {
  if (date.indexOf("T") !== -1) {
    const year = date.slice(0, 4);
    var dateFull =
      date.split("T")[0].slice(5, 10).replace("-", "/").replace("-", "/") +
      "/" +
      year;
    return dateFull;
  } else {
    return date;
  }
}
export function verifyDateTimeFull(date) {
  if (date.indexOf("T") !== -1) {
    const DateTime = new Date(date);
    return DateTime;
  } else {
    return date;
  }
}
export function verifyDateTimeFullText(date, fullText = false) {
  if (date.indexOf("T") !== -1) {
    var TimeFull = date.split("T")[1].slice(0, 5);
    const year = date.slice(0, 4);
    var dateTimeFull = "";
    if (!fullText) {
      dateTimeFull =
        date.split("T")[0].slice(5, 10).replace("-", "/").replace("-", "/") +
        "/" +
        year +
        " " +
        TimeFull;
    } else {
      let DateTime = new Date(date);
      const time = DateTime.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "numeric",
        hour12: true,
      });
      dateTimeFull =
        date.split("T")[0].slice(5, 10).replace("-", "/").replace("-", "/") +
        "/" +
        year +
        " at " +
        time;
    }
    return dateTimeFull;
  } else {
    return date;
  }
}
export function verifyDateTimeAmerican(date) {
  if (date.indexOf("T") !== -1) {
    const year = date.slice(0, 4);
    var dateTimeFull = "";

    let DateTime = new Date(date);
    const time = DateTime.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
    });
    dateTimeFull =
      date.split("T")[0].slice(5, 10).replace("-", "/").replace("-", "/") +
      "/" +
      year +
      " " +
      time;
    return dateTimeFull;
  } else {
    return date;
  }
}
export function findWeekDay(date) {
  const day = date.slice(3, 5);
  const month = date.slice(0, 2);
  const year = date.slice(6, 10);

  let weekDay = new Date(year, month - 1, day).getDay();

  if (weekDay === 1) {
    return "Monday";
  } else if (weekDay === 2) {
    return "Tuesday";
  } else if (weekDay === 3) {
    return "Wednesday";
  } else if (weekDay === 4) {
    return "Thursday";
  } else if (weekDay === 5) {
    return "Friday";
  } else if (weekDay === 6) {
    return "Saturday";
  } else {
    return "Sunday";
  }
}
export function verifyDayMonth(date) {
  if (date.indexOf("T") !== -1) {
    var dateFull = date
      .split("T")[0]
      .slice(5, 10)
      .replace("-", "/")
      .replace("-", "/");
    return dateFull;
  } else {
    return date;
  }
}
export function fillCurrency(value, qntFraction = 2) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: qntFraction,
  });
  return formatter.format(value);
}
export function fillCurrencyWith3Fraction(value) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 3,
  });
  return formatter.format(value);
}
export function fillPercentage(value) {
  var res = value.toString();
  return (res = res + "%");
}

export function mountObjectToSend(obj) {
  return Object.values(obj).reduce((acc, item, i) => {
    const result = {};
    if (item.hasOwnProperty("value")) {
      if (item.value === "" || item.value === undefined) return { ...acc };
      result[Object.keys(obj)[i]] =
        typeof item.value === "string" ? item.value.trim() : item.value;
    } else result[Object.keys(obj)[i]] = mountObjectToSend(item);
    return { ...acc, ...result };
  }, {});
}
export function validateObjectToSend(obj, setObj, listDatesToValidate = []) {
  var res = true;

  Object.entries(obj).length > 0 &&
    Object.entries(obj).forEach((value, key) => {
      if (value[1].required) {
        if (
          value[1].value === undefined ||
          value[1].value === null ||
          value[1].value.toString().trim() === "" ||
          value[1].value === false
        ) {
          setObj((x) => ({
            ...x,
            [value[0]]: {
              value: value[1].value,
              error: ["This field is required!"],
              required: value[1].required,
            },
          }));
          res = false;
        }
      }
      listDatesToValidate.forEach((input) => {
        try {
          if (
            obj[input].value &&
            obj[input].value.toString().indexOf("/") !== -1
          ) {
            if (
              obj[input].value &&
              obj[input].value.split("/")[2].slice(0, 2) === "00"
            ) {
              setObj((x) => ({
                ...x,
                [input]: {
                  ...x[input],
                  error: [
                    "Please provide a valid date with 4 digits for year!",
                  ],
                },
              }));
              res = false;
            }
          }
        } catch (e) {
          console.log(typeof obj[input]);
          console.log(obj[input].value);
          console.log(e);
        }
      });
    });
  return res;
}

export function validateArrayOrganizationToSend(arrayObj, setObj) {
  var res = true;

  arrayObj.organizationSettings.forEach((obj, index) => {
    Object.entries(obj).length > 0 &&
      Object.entries(obj).forEach((value, key) => {
        if (value[1].required) {
          if (
            value[1].value === undefined ||
            value[1].value === null ||
            value[1].value.toString().trim() === "" ||
            value[1].value === false
          ) {
            let newArray = [...arrayObj.organizationSettings];
            newArray[index][value[0]] = {
              value: value[1].value,
              error: ["This field is required!"],
              required: value[1].required,
            };
            setObj({ ...arrayObj, organizationSettings: newArray });
            res = false;
          }
        }
      });
  });
  return res;
}

export function containsObject(obj, list) {
  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === obj || list[i] === obj) {
        return {
          hasItem: true,
          indexItem: i,
        };
      }
    }
  }

  return false;
}

export async function itemSelectedTable(
  item,
  itemSelecteds,
  setCheckSelectTable,
  checkSelectTable,
  listofData
) {
  let element;
  let elementArray = itemSelecteds.slice();

  if (item.length === 0) {
    const selectAll = elementArray.length > 0;
    setCheckSelectTable(!checkSelectTable);
    element = listofData.map((el) => {
      el.selected = selectAll ? !checkSelectTable : !el.selected;
      if (!selectAll) {
        if (!elementArray.includes(el.id)) {
          elementArray.push(el.id);
        } else {
          elementArray = elementArray.filter((id) => id !== el.id);
        }
      }
      return el;
    });

    if (checkSelectTable) {
      elementArray = [];
    }
  } else {
    element = listofData.map((el) => {
      if (el.id === item.id) {
        el.selected = !el.selected;
        if (elementArray.includes(item.id)) {
          elementArray = elementArray.filter((id) => id !== item.id);
        } else {
          elementArray.push(item.id);
        }
      }
      return el;
    });
  }

  return { element, elementArray };
}
export function itemSelectedTableWithStatus(
  item,
  arrayOfItemsSelected,
  itemSelectedToSendEmail,
  setCheckSelectTable,
  checkSelectTable,
  listOfData
) {
  let itemStatus = null;
  let element = [];
  let elementArray = [];
  let elementArrayToSendEmail = [];

  if (item.length === 0) {
    setCheckSelectTable(!checkSelectTable);

    if (!checkSelectTable) {
      element = listOfData.map((el) => {
        el.selected = !checkSelectTable;
        elementArray.push(el.id);
        if (el.status !== "Sent") {
          elementArrayToSendEmail.push(el.id);
        }
        return el;
      });
    } else {
      element = listOfData.map((el) => {
        el.selected = !checkSelectTable;
        return el;
      });
    }
  } else {
    itemStatus = item.status;
    const itemId = item.id;

    element = listOfData.map((el) => {
      if (el.id === itemId) {
        el.selected = !el.selected;
        if (arrayOfItemsSelected.includes(itemId)) {
          elementArray = arrayOfItemsSelected.filter((id) => id !== itemId);
          elementArrayToSendEmail = itemSelectedToSendEmail.filter(
            (id) => id !== itemId
          );
        } else {
          elementArray = arrayOfItemsSelected.slice();
          elementArrayToSendEmail = itemSelectedToSendEmail.slice();
          elementArray.push(itemId);
          if (itemStatus !== "Sent") elementArrayToSendEmail.push(itemId);
        }
        return el;
      }
      return el;
    });
  }

  return { element, elementArray, elementArrayToSendEmail, itemStatus };
}

export async function createdArraySelectMulti(
  form,
  inputName,
  selectOption,
  stateName,
  nomeInputElement,
  valueInput = null
) {
  let valuesSelected = [];
  await selectOption.forEach((x) => {
    if (valueInput) {
      valueInput.forEach((element) => {
        if (element[nomeInputElement] === x.value) {
          valuesSelected.push(x);
        }
      });
    } else {
      form[inputName].value.forEach((element) => {
        if (element[nomeInputElement] === x.value) {
          valuesSelected.push(x);
        }
      });
    }
  });
  stateName(valuesSelected);
}

export async function removedValueSelectMulti(
  form,
  inputName,
  valueSelect,
  nameInputInArray,
  selectState,
  setSelectState
) {
  var filtered = selectState.filter((x) => x.value !== valueSelect.value);

  form[inputName].value = form[inputName].value.filter(
    (x) => x[nameInputInArray] !== valueSelect.value
  );

  setSelectState(filtered);
}

export const CreateObjectToOptionSelect = async (
  json,
  valueLabel = "",
  isNames = false
) => {
  const options = json.map((item) => {
    if (isNames) {
      return {
        value: item.id,
        label: `${item.lastName}, ${item.firstName}`,
      };
    } else {
      return {
        value: item.id,
        label: String(item[valueLabel]),
      };
    }
  });

  return options;
};

export function decodeToken(token = "") {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return jsonPayload;
}

//Logs
export function unstructuredObject(obj) {
  const result = {};
  var inputsTypeDateTime = ["createAt", "updateAt", "deleteAt"];
  function recurse(current, property) {
    if (Array.isArray(current)) {
      for (let i = 0; i < current.length; i++) {
        recurse(current[i], property + "[" + i + "]");
      }
    } else if (typeof current === "object") {
      for (let key in current) {
        if (property) {
          recurse(current[key], property + "_" + key);
        } else {
          recurse(current[key], key);
        }
      }
    } else {
      result[property] =
        inputsTypeDateTime.includes(property) && current !== null
          ? verifyDateTimeFullText(current)
          : current;
    }
  }
  recurse(obj, "");
  return result;
}

export async function compareWithPrevious(arr) {
  var logs = [];
  for (let i = 1; i < arr.length; i++) {
    const current = arr[i];
    const previous = arr[i - 1];
    const objChanges = [];
    for (let key in current) {
      if (current[key] !== previous[key]) {
        objChanges.push(key);
      }
    }
    logs.push({ ...current, objChanges });
  }
  return logs;
}

export function getValues(options, valueToCompar) {
  let valueFounded = "";
  options.forEach((el) => {
    if (el.value === valueToCompar) {
      valueFounded = el.label;
    }
  });
  return valueFounded;
}

export const getBadge = (status) => {
  const BADGE_COLORS = {
    true: "primary",
    false: "danger",
    default: "secondary",
  };

  return BADGE_COLORS[status] || BADGE_COLORS.default;
};

// filter functions
export function setSessionStorage(filters) {
  filters.forEach((filter) => {
    let filterName = filter.name;
    let filterValue = filter.value;

    if (filterValue !== "" && filterValue !== null)
      sessionStorage.setItem(filterName, JSON.stringify(filterValue));
  });
}

export function removeSessionStorage(filters) {
  filters.forEach((filter) => {
    let filterName = filter.name;
    if (sessionStorage.getItem(filterName)) {
      sessionStorage.removeItem(filterName);
    }
  });
}
