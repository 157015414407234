import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CContainer,
  CRow,
  CCol,
  CCardGroup,
} from "@coreui/react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody align="center">
                  <h1>mygroundforce®</h1>
                  <h3>Welcome to mygroundforce® Software</h3>
                  <p className="text-muted">
                    We are pleased to have you with us! To start using the
                    system, we need you to fill in some information about your
                    company, so we can set the system to attend your business in
                    the best way possible.
                  </p>
                </CCardBody>
                <CCardFooter className="p-4">
                  <CRow>
                    <CCol xs="12" align="center">
                      <Link to="/">
                        <CButton color="success" className="px-4">
                          Next Step
                        </CButton>
                      </Link>
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Welcome;
