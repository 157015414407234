import React, { useState, useEffect } from 'react';
import ModelToast from 'src/Components/GForce/Toast/ModelToast';
import { ToastMsgProps } from 'src/Components/Interfaces';
import eventEmitter from 'src/Event';

const TEXT_DEFAULT = 'Record registered successfully';

const ToastsMsgComponent = () => {
    const [toastMsg, setToastMsg] = useState<ToastMsgProps>({
        title: 'Success',
        text: null,
        color: 'toast-dark',
    });

    useEffect(() => {
        const listener = (customText?: ToastMsgProps) => {
            setToastMsg({
                ...toastMsg,
                title: customText?.title || 'Success',
                text: customText?.text || TEXT_DEFAULT,
                color: customText?.color || 'toast-dark',
            });
        };

        eventEmitter.on('toast', listener);

        return () => {
            eventEmitter.off('toast', listener);
        };
    }, [toastMsg]);

    useEffect(() => {
        const listener = () => {
            setToastMsg({
                ...toastMsg,
                text: null,
            });
        };

        eventEmitter.on('toastClear', listener);

        return () => {
            eventEmitter.off('toastClear', listener);
        };
    }, [toastMsg])


    return (
        toastMsg.text && (
            <ModelToast
                data={[{ title: toastMsg.title, text: toastMsg.text }]}
                color={toastMsg.color}
                position="top-right"
                autohide={3000}
            />
        )
    );
};

export default ToastsMsgComponent;