import { CToaster, CToast, CToastHeader, CToastBody } from "@coreui/react";
import React from "react";
import eventEmitter from "src/Event";

const ModelToast = ({ data, position, autohide, color = "toast-danger" }) => {
  const handleCloseToast = (typeMessage, messageIndex) => {
    if (typeMessage === "hiding" || typeMessage === "closing")
      eventEmitter.emitToastClear(messageIndex);
  };

  return (
    <>
      <CToaster position={position}>
        {Object.values(data).map((x, i) => (
          <CToast
            className={
              x.text ===
              "The records before this line were successfully registered."
                ? "toast-dark"
                : color
            }
            color={
              x.text ===
              "The records before this line were successfully registered."
                ? "toast-dark"
                : color
            }
            key={"toast" + i}
            id={"toast" + i}
            show={true}
            autohide={autohide}
            fade={true}
            onStateChange={(state) => handleCloseToast(state, i)}
          >
            <CToastHeader closeButton={true}>
              {x.text ===
              "The records before this line were successfully registered."
                ? "Success"
                : x.title}
            </CToastHeader>
            <CToastBody>{x.text}</CToastBody>
          </CToast>
        ))}
      </CToaster>
    </>
  );
};

export default ModelToast;
