import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faEye,
  faEyeSlash,
  faTrashAlt,
  faEdit,
  faSearch,
  faExclamationCircle,
  faQuestionCircle,
  faCircleArrowUp,
  faCheckCircle,
  faTimesCircle,
  faAddressBook,
  faTruck,
  faPlus,
  faPlusCircle,
  faMinusCircle,
  faFilePdf,
  faFileCsv,
  faTimes,
  faSave,
  faBan,
  faOilCan,
  faPaperclip,
  faKey,
  faExclamationTriangle,
  faSquarePhone,
  faRobot,
  faPercent,
  faFileInvoiceDollar,
  faEnvelope,
  faLock,
  faLockOpen,
  faHandHoldingDollar,
  faGears,
} from "@fortawesome/free-solid-svg-icons";

const FontAwesome = {
  FontAwesomeIcon,
  faPlay,
  faEye,
  faEyeSlash,
  faTrashAlt,
  faEdit,
  faSearch,
  faExclamationCircle,
  faQuestionCircle,
  faCircleArrowUp,
  faCheckCircle,
  faTimesCircle,
  faAddressBook,
  faTruck,
  faPlus,
  faPlusCircle,
  faMinusCircle,
  faFilePdf,
  faFileCsv,
  faTimes,
  faSave,
  faBan,
  faOilCan,
  faPaperclip,
  faKey,
  faExclamationTriangle,
  faSquarePhone,
  faRobot,
  faPercent,
  faFileInvoiceDollar,
  faEnvelope,
  faLock,
  faLockOpen,
  faHandHoldingDollar,
  faGears,
};

export default FontAwesome;
